<template>
  <div class="buying-box">
    <div v-for="label in goodsList" :key="label.id" class="label-item">
      <div class="flex pl8 pr8 pt12 pb12">
        <img :src="label.img" alt="" class="img-class flex-s0 flex-g0" />
        <div class="ml12 flex-s1 flex-g1">
          <div class="fs14 cl17 fw400 txt-line-2 mb8 good-name">
            {{ label.name }}
          </div>
          <div class="flex flex-align-center stock-box">
            <div class="type">{{ label.type }}</div>
            <div class="fs12 fw400 cl99">
              库存：{{ label.reset }}/{{ label.stock }} {{ label.unit }}
            </div>
          </div>
          <div class="flex price-box">
            <div class="now-price">
              <span class="fs12 cl34">抢购价</span>
              <span class="fs12 cl34 price-RMB">￥</span>
              <span
                class="fs20 fw500 cl34"
                v-if="label?.now_price?.split('.')[0]"
              >
                {{ label?.now_price?.split('.')[0] }}
              </span>
              <span
                class="fs12 cl34 fw500"
                v-if="label?.now_price?.split('.')[1]"
              >
                .{{ label?.now_price?.split('.')[1] }}
              </span>
            </div>
            <div class="old-price fs14">
              <span>￥{{ label.price }}</span>
            </div>
          </div>
        </div>
        <!-- <div class="flex-g0 flex-s0 mt18">
          <van-button
            round
            type="primary"
            size="small"
            :disabled="label.inventory_total == 0"
            @click="handleShare(label)"
          >
            发送
          </van-button>
          <div class="edit" @click.stop="toEdit({
            id: '222'
          })">编辑</div>
        </div> -->
      </div>
      <div class="op-box flex pl8 pr8 pt12 pb12">
        <div class="btn" @click.stop="toEdit(label.id)">编辑</div>
        <div class="btn" @click.stop="toDel(label.id)">删除</div>
        <div class="btn" @click.stop="toOrderList(label.id)">订单</div>
        <div class="btn" @click.stop="handleShare(label)">分享到群</div>
      </div>
      <div class="bdb"></div>
    </div>
    <div class="buy-btn" @click.stop="toEdit(undefined)">快速创建抢购商品</div>
    <ModalView
      v-model="modalVisible"
      async-close
      @confirm="handleDel"
      @cancel="modalVisible = false"
    >
      <div class="cl32 cl29 fw500 pt30 pb50 tc">确定删除该抢购商品吗?</div>
    </ModalView>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Toast } from 'vant'
import ModalView from '@/components/modalView/index.vue'
import { sendChatMessageAllType } from '@/utils/nwechat.js'
const router = useRouter()
const store = useStore()
const goodsList = computed(() => {
  return store.state.mockGoods.list
})
const modalVisible = ref(false)
const toEdit = (id) => {
  router.push({
    path: '/buyingGoods/edit',
    query: {
      id
    }
  })
}
const toOrderList = (id) => {
  router.push({
    path: '/buyingGoods/orderList',
    query: {
      id
    }
  })
}
const handleShare = async (item) => {
  Toast.loading({
    message: '加载中',
    forbidClick: true,
    duration: 0
  })
  // recordDynamic(item.goods_id)
  try {
    let title = item.name
    let imgUrl = item.img
    let page = `pages/goodsDetail/index.html?goods_id=${item.id}&is_share=1`
    // page = await formatParamsDistribution(
    //   page,
    //   props.externalUserId,
    //   props.chatId,
    //   'b2c_goods'
    // )

    // 如果存在埋点id加入埋点id
    // if (share_data_id.value) page = `${page}&share_data_id=${share_data_id.value}`

    let shareObj = {
      appid: 'wxbecd55f783f6a0cd', //小程序的appid 群店:wxbecd55f783f6a0cd; aosifen: wx754859a6c762e762
      title,
      imgUrl, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
      page //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
    }
    // console.log('发送前参数', shareObj)
    const { sendStatus } = await sendChatMessageAllType('miniprogram', {
      miniprogram: { ...shareObj }
    })
    // 如果发送成功， 则调用记录动态接口
    // if (sendStatus) recordDynamic(item.goods_id)

    Toast.clear()
  } catch (e) {
    console.log('报错', e)
    Toast.clear()
  }
}
const delId = ref('')
const toDel = (id) => {
  modalVisible.value = true
  delId.value = id
}
const handleDel = () => {
  store.commit('mockGoods/DEL_LIST', delId.value)
  delId.value = ''
  modalVisible.value = false
}
</script>
<style>
#app {
  background-color: #fff;
}
</style>
<style lang="less" scoped>
.buying-box {
  height: calc(100vh - 84px - env(safe-area-inset-bottom));
  overflow-y: auto;
}
.buy-btn {
  width: calc(100vw - 30px);
  margin: 0 auto;
  height: 44px;
  line-height: 44px;
  background: #1773fa;
  border-radius: 4px;
  text-align: center;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  position: fixed;
  left: 15px;
  z-index: 3;
  bottom: calc(32px + env(safe-area-inset-bottom));
}
.good-name {
  height: 38px;
}
.op-box {
  border-top: 1px solid #edeff2;
  .btn {
    flex-basis: auto;
    flex: 1;
    text-align: center;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #8c8c8c;
    letter-spacing: 0;
    border-right: 1px solid #d8d8d8;
    &:last-child {
      border-right: none;
    }
  }
}
.type {
  width: 34px;
  height: 18px;
  background-image: linear-gradient(117deg, #fd8b04 18%, #f86e08 79%);
  border-radius: 2px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  margin-right: 8px;
}
.stock-box {
  margin-bottom: 2px;
}
.price-box {
  height: 30px;
  .now-price {
    margin-right: 4px;
    .price-RMB {
      letter-spacing: -2px;
    }
  }
  .old-price {
    align-self: flex-end;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #8c8c8c;
    letter-spacing: 0;
    line-height: 18px;
    text-decoration: line-through;
  }
}
.text-color {
  color: rgba(23, 23, 23, 0.6);
}
.container {
  height: 100vh;
}
.container-header {
  padding: 14px 15px 16px 15px;
}
.container-wrapper {
  overflow: hidden;

  .menu-wrapper,
  .label-wrapper {
    height: calc(100vh - 109px);
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    scrollbar-track-color: transparent;
    -ms-scrollbar-track-color: transparent;
  }
  .menu-wrapper::-webkit-scrollbar {
    display: none;
  }
  .menu-wrapper {
    width: 90px;
    padding-bottom: 140px;
    background-color: #f7f7f7;

    :deep(.van-sidebar) {
      width: 100%;

      .van-sidebar-item {
        background-color: #f7f7f7;
        padding: 12px 15px;
      }
      .van-sidebar-item--select {
        background-color: #fff;
      }
      .van-sidebar-item--select::before {
        width: 0;
      }
    }
    .menu-item-last {
      border-bottom-right-radius: 6px;
    }
    .menu-item-next {
      border-top-right-radius: 6px;
    }
  }
  .label-wrapper {
    padding: 0 8px;

    .label-cell {
      .label-item {
        padding: 4px 0;
        margin-bottom: 4px;
        background-color: #fff;
        .label-item-title {
          height: 22px;
          line-height: 22px;
          padding: 0 8px;
          border-radius: 2px;
          color: rgba(0, 0, 0, 0.65);
          background: rgba(0, 0, 0, 0.04);
          border: 1px solid rgba(0, 0, 0, 0.15);
        }
        .label-item-active {
          color: #fff;
          background: rgba(255, 111, 0, 0.8);
          border: none;
        }
      }
    }
  }
}
// 模拟搜索框
.imitate-search-box {
  padding: 12px 16px;
  padding-top: 4px;
  background-color: #fff;
  display: flex;
  align-items: center;

  .imitate-input {
    line-height: 32px;
    background-color: #f5f5f5;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999999;
    border-radius: 5px;
    cursor: pointer;

    flex-grow: 1;
    .imitate-placehodor {
      margin-left: 8px;
    }
  }
}
.img-class {
  width: 99px;
  height: 99px;
}
.label-item {
  background-color: #fff;
}
</style>
